import React from "react";

const ReferralPage = () => {
  return (
    <div className="referral-wrapper">
      <h1>this feature is coming up soon</h1>
    </div>
  );
};

export default ReferralPage;
