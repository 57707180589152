const data = {
  orderItems: [
    { name: "Select here" },
    { id: 1, name: "Agbada, Buba & Sokoto (1pair - 3pcs)", price: 1500 },
    { id: 2, name: "Bag (School/hand bag)", price: 500 },
    { id: 3, name: "Bath Mat", price: 500 },
    { id: 4, name: "Bathrobe", price: 500 },
    { id: 5, name: "Bedsheet", price: 500 },
    { id: 6, name: "BED TOPPER", price: 5000 },
    { id: 7, name: "Blazers/Jacket (dry-cleaning)", price: 800 },
    { id: 8, name: "Blouse/Camisole/Female tops", price: 400 },
    { id: 9, name: "Boubou", price: 700 },
    { id: 10, name: "Boxers", price: 200 },
    { id: 11, name: "Buba & Sokoto (1 pair -2pcs)", price: 500 },
    { id: 12, name: "Buba - Native top (1pc)", price: 250 },
    { id: 13, name: "Chair cover Large", price: 300 },
    { id: 14, name: "Dress & Jacket 2pcs (dry-cleaning)", price: 1500 },
    { id: 15, name: "DRESS/GOWN", price: 2200 },
    { id: 16, name: "HOODIE", price: 500 },
    { id: 17, name: "IRO & BUBA (2pcs)", price: 500 },
    { id: 18, name: "IRO,BUBA & SCARF (3pcs)", price: 200 },
    { id: 19, name: "Jacket (Drycleaning)", price: 200 },
    { id: 20, name: "Jalamia", price: 400 },
    { id: 21, name: "Jumpsuit", price: 500 },
    { id: 22, name: "Lawyers Bib/wig/collar", price: 600 },
    { id: 23, name: "Overall - Up & Down (2pcs)", price: 800 },
    { id: 24, name: "Pillow (with the foam)", price: 1300 },
    { id: 25, name: "Pillow case", price: 250 },
    { id: 26, name: "Pull over", price: 500 },
    { id: 27, name: "Scarf/Native cap/face cap", price: 400 },
    { id: 28, name: "Shawl/Apron/ Napkin", price: 400 },
    { id: 29, name: "SHIRT (male/female)", price: 500 },
    { id: 30, name: "Short", price: 250 },
    { id: 31, name: "SKIRT", price: 400 },
    { id: 32, name: "Suits 2pcs (dry-cleaning)", price: 1700 },
    { id: 33, name: "SWEATER", price: 700 },
    { id: 34, name: "T-SHIRT/POLO", price: 400 },
    { id: 35, name: "Throw pillow", price: 800 },
    { id: 36, name: "TROUSERS", price: 500 },
    { id: 37, name: "Towel (large)", price: 600 },
    { id: 38, name: "Winter jacket", price: 1200 },
  ],
};

export default data;
